<template>
  <EndTestBackground :testType="1">
    <div class="end-test">
      <div>
        <span class="green">{{ userInfo.studentName }}</span> 同学，恭喜你完成
        <span class="green">{{ reportName() }}</span>
      </div>
      <div>
        本次词汇部分的测评得分：<span class="green"
          >{{ value.testScore }}分</span
        >
      </div>
      <div>
        获得 测评答题
        <pybWord :pybNum="value.answerRewardNumber"></pybWord> 关卡奖励
        <pybWord :pybNum="value.sprintRewardNumber"></pybWord>
        ，已纳入你的后勤补给！
      </div>
      <div v-if="this.$route.query.text == 2">
        <span class="green">"语法与功能"</span>的靶向训练已自动解锁，欢迎挑战
      </div>
      <div v-else>
        定级词汇冲刺难度：<span class="green">{{ value.difficulty }}</span>
      </div>
      <div class="end-button">
        <GlobalButton
          buttonType="round"
          buttonText="继续闯关"
          @afterClick="continueSprint"
        ></GlobalButton>
        <GlobalButton
          buttonType="iconline"
          buttonText="查看报告"
          buttonColor="black"
          @afterClick="toReport"
        ></GlobalButton>
      </div>
    </div>
  </EndTestBackground>
</template>

<script>
import GlobalButton from "@/components/global-button/global-button-enter.vue";
import pybWord from "@/components/key-words/pyb-key-word.vue";
import EndTestBackground from "@/components/backgroung/end-test-background.vue";
import { getEngFinishProgress } from "@/api/english-small";
import { mapState } from "vuex";
export default {
  data() {
    return {
      value: "",
    };
  },
  components: {
    GlobalButton,
    pybWord,
    EndTestBackground,
  },
  created() {
    this.getPageContent();
  },
  computed: {
    ...mapState(["mockTestName","userInfo"]),
  },
  methods: {
    reportName() {
      if (this.$route.query.text == 2) {
        return "中考英语冲刺扫雷尖兵词汇部分效果测评";
      } else {
        return "中考英语冲刺扫雷尖兵词汇部分靶向诊断";
      }
    },
    getPageContent() {
      getEngFinishProgress({ recordExamId: this.$route.query.studentExamId }).then((res) => {
        console.log("靶向完成页", res);
        this.value = res.data.result;
      });
    },
    continueSprint() {
      // 1 靶向诊断 2.靶向训练 3.效果测评 4.语法
      if (this.value.stage == 2) {
        this.$router.replace({
          name: "SmallModuleWordList",
          query: {
            // module: 1,
          },
        });
      }
       if (this.value.stage == 4) {
        this.$router.replace({
          name: "SmallModuleGrammarList",
          query: {
            // module: 1,
          },
        });
      }
    },
    toReport() {
      this.$router.replace({
        name: "SmallModuleReport",
        query: {
          // module: 5,
          studentExamId: this.$route.query.studentExamId,
          text: this.$route.query.text,
          stageId: this.$route.query.stage,
          sprintId: 2,
        },
      });
    },
  },
};
</script>

<style lang="scss" scoped>
.end-test {
  & > div {
    padding: 0.7rem 0;
  }
  .end-button {
    padding: 2rem 0;
  }
  .green {
    color: #2cb774;
    font-weight: bold;
  }
}
</style>